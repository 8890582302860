import React, { useState } from 'react';

import { navigate } from 'gatsby';
import { useQueryParam, BooleanParam } from 'use-query-params';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { alpha, makeStyles } from '@material-ui/core/styles';

import Loading from '../Common/Loading';
import { Dropzone } from '../Common/Dropzone/Dropzone';
import { FullViewHeader as Header } from '../Common/FullViewHeader';
import { TextTransform } from '../Common/styled/TextTransform';
import { Error } from '../Common/styled/Form';

import { CREATE_MEDICAL_CASE_FILE, CREATE_HOSPITAL_FILE } from '../../graphql/mutations';
import { Margin } from '../Common/styled/Margins';
import { ButtonUI } from '../../componentsUI/Button';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { Row } from '../Common/styled/Groups';

const useStyles = makeStyles((theme) => ({
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  wrapper: {
    display: 'flex',
    margin: '1.25em 0',
    justifyContent: 'left',
  },
}));

export const UploadForm = ({ elementUuid }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(false);
  const [file, setFile] = useState('');
  const [uploadToHospital] = useQueryParam('uth', BooleanParam);
  const pageTitleIndex = uploadToHospital ? 'upload.hospital.file' : 'upload.case.file';
  const classes = useStyles();

  const [createMedicalCaseFile, { loading: updatingMCF }] = useMutation(CREATE_MEDICAL_CASE_FILE, {
    onCompleted() {
      navigate(`/case/${elementUuid}`);
    },
  });
  const [createPatientFile, { loading: updatingPF }] = useMutation(CREATE_PATIENT_FILE, {
    onCompleted() {
      navigate(`/case/${elementUuid}`);
    },
  });
  const [createHospitalFile, { loading: updatingHF }] = useMutation(CREATE_HOSPITAL_FILE, {
    onCompleted() {
      navigate('/files');
    },
  });

  const handleSubmit = async () => {
    setServerError(false);
    try {
      if (!uploadToHospital) {
        const input = {
          medicalCaseUuid: elementUuid,
          file,
        };
        await createMedicalCaseFile({ variables: { input } });
      } else {
        const input = {
          hospitalUuid: elementUuid,
          file,
        };
        await createHospitalFile({ variables: { input } });
      }
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) setServerError(errors.graphQLErrors[0].message);
    }
  };

  return (
    <ScrollableContainer padding="1em .75em">
      <TextTransform>
        <Header title={t(pageTitleIndex)} />
      </TextTransform>

      <div>
        <Dropzone accept="all" setFile={(_file) => setFile(_file)} currentFileUrl={file || ''} />

        {(updatingMCF || updatingHF) && (
          <div className="my-5">
            <Loading />
          </div>
        )}
        {serverError ? <Error>{serverError}</Error> : null}
        <Margin mx-0>
          <Row>
            <ButtonUI
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              disabled={!file}
              onClick={() => handleSubmit()}
            >
              {t('upload.file')}
            </ButtonUI>
          </Row>
        </Margin>
      </div>

    </ScrollableContainer>
  );
};
