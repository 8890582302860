import React from 'react';

import Layout from '../../components/MenuLayout';
import { PageContent } from '../../components/Common/styled/PageContent';
import { UploadForm } from '../../components/File/UploadForm';

export default ({ elementUuid }) => (
  <Layout>
    <PageContent>
      <UploadForm elementUuid={elementUuid} />
    </PageContent>
  </Layout>
);
